@import "../../../styles/main";

.Form {
  border: 1px solid $GreenLightColor;
  padding: 10px 20px 10px 20px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;

  .Buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin: 30px 20px 20px 20px;
    }

    @include mobile {
      flex-direction: column;
      padding-top: 10px;

      button {
        margin: 10px;
      }
    }
  }
}
