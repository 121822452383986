@import "variables";

%InputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 0 !important;
    margin-left: 10px;
  }

  .Input {
    margin: 5px 0 0 0 !important;
    width: auto;
    border: 1px solid $GrayColor;
    border-radius: 15px;
    padding: 0 20px 0 10px !important;
    box-sizing: border-box;
    height: 35px !important;
    outline: none;
    background-color: white;

    &.Select {
      background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
      -webkit-appearance: none;
      background-position-x: calc(100% - 25px);;
      background-color: white !important;
    }
  }

  .TextError {
    color: $RedColor;
    margin-top: 10px;
    font-size: 12px;
  }
}

%Button {
  height: 45px;
  box-sizing: border-box;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  padding: 10px 20px !important;
  margin: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  color: #FFF;
  outline: none;
  text-shadow: 1px 1px 1px $GrayDarkColor;
  display: flex;
  align-items: center;
  justify-content: center;
}
