@import "mixins";

.Cols2 {
  width: 100%;
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }

  .Col {
    flex: 50%;
    padding: 0 20px 0 20px;
  }
}


.Cols3 {
  width: 100%;
  display: flex;
  flex-direction: row;

  @include mobile {
    flex-direction: column;
  }

  .Col {
    flex: 33.33%;
    padding: 0 20px 0 20px;
  }
}
