// ------------------------------------------//
// Colors
// ------------------------------------------//
$GrayColor: #607d8b;
$GrayDarkColor: #37474f;
$GrayLightColor: #b0bec5;

$BlueColor: #00aeef;
$BlueColor2: #00a7b8;

$PurpleColor: #ae3e97;

$OrangeColor: #f47b29;

$GreenColor: #00904c;
$GreenLightColor: #c8e6c9;

$RedColor: #F5333F;
$RedLightColor: #ffcdd2;

$OrangeColor2: #F15A24;


// ------------------------------------------//
// Responsive media queries
// ------------------------------------------//
$desktop-media-query: "(min-width: 1024px)";
$tablet-media-query: "(min-width: 768px) and (max-width: 1023px)";
$phone-media-query: "(min-width: 320px) and (max-width: 767px)";
