@import "placeholders";
@import "variables";
@import "mixins";
@import "grid";

* {
  font-family: 'Arial', sans-serif;
}

p {
  font-size: 18px;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;
}

.BigTitle {
  font-size: 3em;
  color: #FFF;
  margin: 0;

  @include mobile {
    font-size: 3em;
    margin: 10px;
  }
}

.Title {
  color: #00a7b8;
  font-size: 2.5em;
  margin-bottom: 0 !important;
}

.Subtitle {
  color: #ae3e97;
  font-size: 2em;
  margin-bottom: 0 !important;
}

.Subtitle2 {
  color: #f47b29;
  font-size: 1.8em;
  margin-bottom: 0 !important;
}

.Subtitle3 {
  color: #00904c;
  font-size: 1.5em;
  margin-bottom: 0 !important;
}

.AlertText {
  color: #F5333F;
  font-size: 1.5em;
  margin-bottom: 0 !important;
}

.AlertText2 {
  color: #F15A24;
}

.TextCenter {
  text-align: center !important;
}

.TextLeft {
  text-align: left !important;
}

.TextRight {
  text-align: right !important;
}

.BtnInfo {
  @extend %Button;
  background-color: $BlueColor;
}

.BtnImportant {
  @extend %Button;
  background-color: $PurpleColor;
}

.BtnAdvice {
  @extend %Button;
  background-color: $OrangeColor;
}

.BtnConfirm {
  @extend %Button;
  background-color: $GreenColor;
}

.BackGround1 {
  background-color: $BlueColor;
}

.BackGround2 {
  background-color: $BlueColor2;
}

.BackGround3 {
  background-color: $PurpleColor;
}

.BackGroundAlert {
  background-color: $OrangeColor;
}

.BackGroundCard {
  background-color: #F2F2F2;
}

.InputContainer {
  @extend %InputContainer;
}

.Item {
  padding: 10px 10px 2px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.05);
}

.RedText {
  color: $RedColor;
}
